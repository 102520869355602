import React, { useState, useRef } from 'react';
import { Dialog, Stack, Box, Button, Snackbar } from '@mui/material';

export default function ShareDialog(props) {
    const { onClose, open, shareUrl, shareText } = props;
    const inputRef = useRef(null);
    const [openMessage, setOpenMessage] = useState(false);
    const [message, setMessage] = useState('');

    const handleClose = () => {
        onClose();
    }

    const handleCloseMessage = (event, reason) => {
        setOpenMessage(false);
    };

    const handleLine = () => {
        window.open(`https://line.me/R/msg/text/?${encodeURIComponent(shareUrl)}`, '_blank');
    }

    const handleMail = () => {
        const mailtoLink = `mailto:?body=${encodeURIComponent(shareUrl)}`;
        window.location.href = mailtoLink;
    }

    const handleTwitter = () => {
        const url = encodeURIComponent(shareUrl);
        const text = encodeURIComponent(shareText);
        const twitterShareURL = `https://twitter.com/intent/tweet?url=${url}&text=${text}`;
        window.open(twitterShareURL, '_blank');
    }

    const handleCopy = () => {
        const input = inputRef.current;
        if (!input) return;

        if (!navigator.clipboard) {
            input.select();
            document.execCommand('copy');
            window.getSelection().removeAllRanges();

        } else {
            const textToCopy = input.value;
            navigator.clipboard.writeText(textToCopy)
                .then(() => {
                    setMessage("リンクをコピーしました");
                    setOpenMessage(true);
                })
                .catch((error) => {
                    alert('コピーできませんでした\n手動でコピーしてください');
                    console.error('Failed to copy text to clipboard:', error);
                });
        }
    }

    return (
        <>
            <Dialog onClose={onClose} aria-labelledby="simple-dialog-title" open={open} fullWidth>
                <div>
                    <Stack spacing={2}>
                        <Box
                            display="flex"
                            justifyContent='flex-end'
                            height="3vh"
                            style={{
                                margin: '10px 5px 0px 0px'
                            }}
                        >
                            <div
                                onClick={handleClose}
                                style={{ cursor: 'pointer', width: '7%', height: '100%', display: 'block', fill: 'currentcolor' }}>
                                <svg xmlns="http://www.w3.org/2000/svg" enableBackground="new 0 0 24 24" height="24" viewBox="0 0 24 24" width="24" focusable="false" style={{ pointerEvents: 'none', display: 'inherit', width: '100%', height: '100%' }}><path d="m12.71 12 8.15 8.15-.71.71L12 12.71l-8.15 8.15-.71-.71L11.29 12 3.15 3.85l.71-.71L12 11.29l8.15-8.15.71.71L12.71 12z"></path></svg>
                            </div>
                        </Box>
                        <Box
                            display="flex"
                            flexDirection="row"
                            justifyContent="center"
                            alignItems="center"
                            height="10vh"
                            gap={2}
                        >
                            <div
                                onClick={handleLine}
                                style={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    height: '100%',
                                    width: '10vw',
                                    cursor: 'pointer'
                                }}
                            >
                                <img
                                    alt={'line-share'}
                                    draggable="false"
                                    src={`/LINE.png`}
                                    style={{
                                        maxHeight: "100%",
                                        maxWidth: "100%"
                                    }}
                                />
                            </div>
                            <div
                                onClick={handleMail}
                                style={{ height: '100%', width: '10vw', cursor: 'pointer' }}
                            >
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 60 60" focusable="false" style={{ pointerEvents: 'none', display: 'inherit', width: '100%', height: '100%' }}>
                                    <g fillRule="nonzero" fill="none">
                                        <path d="M28.4863253 59.9692983c-6.6364044-.569063-11.5630204-2.3269561-16.3219736-5.8239327C4.44376366 48.4721168 3e-7 39.6467924 3e-7 29.9869344c0-14.8753747 10.506778-27.18854591 25.2744118-29.61975392 6.0281072-.9924119 12.7038532.04926445 18.2879399 2.85362966C57.1386273 10.0389054 63.3436516 25.7618627 58.2050229 40.3239688 54.677067 50.3216743 45.4153135 57.9417536 34.81395 59.5689067c-2.0856252.3201125-5.0651487.5086456-6.3276247.4003916z" fill="#888"></path>
                                        <path d="M40.531502 19.160814h-22c-1.74 0-2.986 1.2375-3 3v16c0 1.7625 1.26 3 3 3h22c1.74 0 3-1.2375 3-3v-16c0-1.7625-1.26-3-3-3zm0 6l-11 7-11-7v-3l11 7 11-7v3z" fill="#FFF"></path>
                                    </g>
                                </svg>
                            </div>
                            <div
                                onClick={handleTwitter}
                                style={{ height: '100%', width: '10vw', cursor: 'pointer' }}
                            >
                                <svg width="192" height="192" viewBox="0 0 192 192" fill="none" xmlns="http://www.w3.org/2000/svg" focusable="false" style={{ pointerEvents: 'none', display: 'inherit', width: '100%', height: '100%' }}>
                                    <rect width="192" height="192" rx="96" fill="black"></rect>
                                    <path fillRule="evenodd" clipRule="evenodd" d="M42 47H76L100 78.5L127 47H144L107.5 88.5L150 145H117L91 111L61 145H44L83 100.5L42 47ZM62 57H71.5L130.5 135H121.5L62 57Z" fill="white"></path>
                                </svg>
                            </div>
                        </Box>
                        <Box>
                            <div style={{
                                border: '1px solid rgba(225, 225, 225, 0.12)',
                                borderRadius: '10px',
                                padding: '10px',
                                margin: '0px 20px 0px 20px',
                            }}>
                                <Box
                                    display="flex"
                                    flexDirection="row"
                                >
                                    <input
                                        readOnly
                                        ref={inputRef}
                                        style={{
                                            border: 'none',
                                            overflow: 'hidden',
                                            whiteSpace: 'nowrap',
                                            color: 'white',
                                            backgroundColor: 'transparent',
                                            width: '70vw'
                                        }}
                                        value={shareUrl} />
                                    <Button
                                        onClick={handleCopy}
                                        style={{
                                            margin: '0px 0px 0px 10px',
                                            border: 'none',
                                            borderRadius: '20px',
                                            backgroundColor: 'rgb(17, 17, 17)'
                                        }}
                                    >コピー</Button>
                                </Box>
                            </div>
                        </Box>
                        <Box></Box>
                    </Stack>
                </div>
                <Snackbar
                    open={openMessage}
                    autoHideDuration={5000}
                    onClose={handleCloseMessage}
                    message={message}
                    ContentProps={{
                        sx: {
                            backgroundColor: 'rgb(28, 28, 28)',
                            color: 'rgb(225, 225, 225)',
                        },
                    }}
                />
            </Dialog>
        </>
    );
}
