import { format } from 'date-fns'
import jaLocale from "date-fns/locale/ja";
import DateFnsUtils from '@date-io/date-fns';

/**
 * カレンダーで使っている
 */
class ExtendedUtils extends DateFnsUtils {
  getCalendarHeaderText(date) {
    return format(date, "yyyy MMM", { locale: this.locale });
  }
  getDatePickerHeaderText(date) {
    return format(date, "MMMd日", { locale: this.locale });
  }
}

const param = {
  ja: {
    subject: 'Contact',
    name: 'お名前（活動名）',
    link: 'リンク（ご活動内容が分かるURLをhttpから入力）',
    company: '会社名',
    forCompany: '企業の方',
    forIndividual: '個人の方',
    mail: 'メールアドレス',
    hear: 'ご連絡先（メール、Twitter等）',
    title: '件名',
    description: 'ご依頼内容',
    limit: '納期',
    money: 'ご予算（円）',
    exMoney: '\n(例）\nMV用イラスト 3〜5万円\nグッズ用イラスト 〜10万円',
    send: '送信する',
    sending: '送信中',
    sent: '送信済み',
    yen: '円',
    dateLocal: jaLocale,
    dateUtils: ExtendedUtils,
    dateFormat: 'yyyy/MM/dd',
    toolbarFormat: 'MMMd日',
    monthAndYear: "yyyy/MM",
    ok: '決定',
    cancel: 'キャンセル',
    complete: '送信完了しました',
    completeMessage: '自動返信メールをご確認ください。',
    sendError: '送信失敗しました',
    sendErrorMessage: '現在送信できないようです。\ninfo@hoshiro.co.jpまでご依頼内容を送信してください。',
    individualMessage: '現在個人で新規の方のご依頼を停止しています。\n知り合いの方は以下のフォームよりご依頼内容を送信してください。',
    updated: '入力中に情報が更新されたため送信されませんでした。再度入力して送信してください。',
    black: '送信できません',
    blackMessage: 'お使いの端末は利用が制限されています。',
    orderStepTitle: 'ご依頼の流れ',
    orderStep: [
      {
        label: 'ご依頼',
        description: `以下のフォームよりご依頼内容を送信して下さい。ご入力のメールアドレスに自動返信メールが届きますのでご確認ください。メール受信許可設定が必要な場合はinfo@hoshiro.co.jpの許可をお願い致します。`,
      },
      {
        label: 'ご依頼内容の確認',
        description:
          '入力して頂いたメールアドレス又は「ご連絡先」にこちらからご連絡させて頂きますのでお待ちください。',
      },
      {
        label: 'お見積もり',
        description: `お見積もりを作成致します。内容に了承頂ける場合はご依頼内容を確定とさせて頂き、制作作業に入ります。`,
      },
      {
        label: 'ラフの確認',
        description: `構図やイラストに含める要素のご確認をお願い致します。`,
      },
      {
        label: 'ご請求',
        description: `請求書を発行致しますので期限までにお支払いください。`,
      },
      {
        label: '最終確認・納品',
        description: `完成品をご確認頂きます。細かな修正がございましたらお申し付けください。`,
      },
    ],
  },
  en: {
    subject: 'Contact',
    name: 'Name（Screen name）',
    link: 'Link（Your homepage url starts with http.）',
    company: 'Company name',
    forCompany: 'Companies',
    forIndividual: 'Individuals',
    mail: 'Mail address',
    hear: 'Your contact information (Mail, Twitter, etc.)',
    title: 'Subject',
    description: 'Request details',
    limit: 'Deadline',
    money: 'Budget（JPY）',
    exMoney: '\ne.g.\nIllustrations\nfor music video 30,000〜50,000JPY\nfor goods 〜100,000JPY',
    send: 'Send',
    sending: 'Sending',
    sent: 'Sent',
    yen: 'JPY',
    dateLocal: null,
    dateUtils: DateFnsUtils,
    dateFormat: 'd MMM yyyy',
    toolbarFormat: null,
    monthAndYear: "LLLL yyyy",
    ok: 'OK',
    cancel: 'Cancel',
    complete: 'Your request has been sent.',
    completeMessage: 'Please confirm auto-reply email.',
    sendError: 'Sending failed.',
    sendErrorMessage: 'Something was wrong. \nPlease send a request to info@hoshiro.co.jp',
    individualMessage: 'We are currently suspending requests from new individuals.\nIf you have requested before, please fill up the form and send it to us',
    updated: 'Sending aborted because data has changed. Please send again.',
    black: 'Restricted',
    blackMessage: 'Your host is banned.',
    orderStepTitle: 'Flow of Request',
    orderStep: [
      {
        label: 'Request',
        description: `Please submit your request using the form below. You will receive an auto-reply email to the address you provide.
            If you need to set up your mail receive permission, please allow "info@hoshiro.co.jp".`,
      },
      {
        label: 'Confirm request',
        description:
          'Please wait for us to contact you at the email or "contact information" you entered.',
      },
      {
        label: 'Price estimate',
        description: `We will prepare a quotation. If you agree with the contents, your request will be finalized and we will start the production work.`,
      },
      {
        label: 'Rough confirmation',
        description: `Please confirm the composition and elements to be included in the illustration.`,
      },
      {
        label: 'Invoice',
        description: `We will issue an invoice, so please pay by the due date.`,
      },
      {
        label: 'Final confirmation and delivery',
        description: `Please check the finished product. Please let us know if there are any minor modifications.`,
      },
    ],
  }
}

export default param;